import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function countryTaxes(countryId) {
  const res = await graphqlClient.query({
    query: gql`
      {
        tax(where:{country_id:{_eq:"${countryId}"}}) {
          id
          name
          type
          proportion
          rate
        }
      }
    `
  });

  return res.data.tax;
}

async function add(taxId, projectId, proportion) {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation($tax: project_tax_insert_input!) {
        insert_project_tax_one(object: $tax) {
          id
        }
      }
    `,
    variables: {
      tax: {
        tax_id: taxId,
        project_id: projectId,
        proportion: proportion
      }
    }
  });

  return res.data.insert_project_tax_one;
}

async function remove(taxId) {
  const res = await graphqlClient.mutate({
    mutation: gql`mutation {
			delete_project_tax_by_pk(id:"${taxId}") {
				id
			}
		}`
  });

  return res.data.delete_discount_by_pk;
}

async function list(projectId) {
  const res = await graphqlClient.query({
    query: gql`
    {
			project_tax(where:{project_id:{_eq:"${projectId}"}}) {
				id
				tax {
					id
					name
					type
          rate
          proportion
				}
				proportion
			}
		}
    `
  });

  return res.data.project_tax;
}

export default {
  countryTaxes,
  add,
  remove,
  list
};
