import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

const listTypes = async () => {
  const res = await graphqlClient.query({
    query: gql`
      {
        discount_type {
          id
          name
        }
      }
    `,
  });

  return res.data.discount_type;
};

const list = async (projectId) => {
  const res = await graphqlClient.query({
    query: gql`{
			discount(where:{project_id:{_eq:"${projectId}"}},
      order_by:{
        active: desc
      }) {
				id
				active
				name
				discount_type {
						id
						name
					}
				type
				value
			}
		}`,
  });

  return res.data.discount;
};

const add = async (name, active, type, value, projectId, description) => {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation ($discount: discount_insert_input!) {
        insert_discount_one(object: $discount) {
          id
        }
      }
    `,
    variables: {
      discount: {
        name: name,
        active: Boolean(active),
        type: type,
        value: value,
        project_id: projectId,
        description: description
      },
    },
  });

  return res.data.insert_discount_one;
};

const remove = async (discountId) => {
  const res = await graphqlClient.mutate({
    mutation: gql`mutation {
			delete_discount_by_pk(id:"${discountId}") {
				id
			}
		}`,
  });

  return res.data.delete_discount_by_pk;
};

const get = async (id) => {
  const res = await graphqlClient.query({
    query: gql`
    {
      discount(where:{
        id:{
          _eq: "${id}"
        }
      }) {
        id
        name
        active
        value
        description
        discount_type {
          id
          name
        }
      }
    }`,
  });

  return res.data.discount ? res.data.discount[0] : null;
};

const update = async (discountId, fields) => {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation updDiscountById($fields: discount_set_input!){
        update_discount(where:{
          id: {
            _eq: "${discountId}"
          }
        },_set:$fields) {
          affected_rows
          returning {
            id
            name
            type
            value
          }
        }
      }
    `,
    variables: {
      fields: fields,
    },
  });

  return res.data.update_discount;
};

export default {
  listTypes,
  list,
  add,
  remove,
  get,
  update,
};
