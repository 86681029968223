import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

const list = async projectId => {
  const res = await graphqlClient.query({
    query: gql`{
			project_extra(where:{project_id:{_eq:"${projectId}"}},
      order_by:{
        active: desc
      }) {
				id
				active
				name
				value
        description
			}
		}`
  });

  return res.data.project_extra;
};

const add = async (name, active, value, description, projectId) => {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation($extra: project_extra_insert_input!) {
        insert_project_extra_one(object: $extra) {
          id
        }
      }
    `,
    variables: {
      extra: {
        name: name,
        active: Boolean(active),
        value: value,
        description: description,
        project_id: projectId
      }
    }
  });

  return res.data.insert_project_extra_one;
};

const remove = async extraId => {
  const res = await graphqlClient.mutate({
    mutation: gql`mutation {
			delete_project_extra_by_pk(id:"${extraId}") {
				id
			}
		}`
  });

  return res.data.delete_discount_by_pk;
};

const upd = async (extraId, extra) => {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation upd($id: uuid!, $set: project_extra_set_input) {
        update_project_extra_by_pk(
          pk_columns:{
            id: $id
          },
          _set: $set
        ) {
          id
        }
      }
    `,
    variables: {
      id: extraId,
      set: {
        name: extra.name,
        value: extra.value,
        description: extra.description,
        active: extra.active
      }
    }
  });

  return res.data.update_project_extra_by_pk;
};

export default {
  list,
  add,
  remove,
  upd
};
