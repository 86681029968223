import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function get(projectId, type = null) {
  const response = await graphqlClient.query({
    query: gql`
      query projectUnitTypes($projectId: uuid, $type: String) {
        project_unit_type(
          where: { project_id: { _eq: $projectId }, type: { _eq: $type } }
        ) {
          id
          type
          project_id
        }
      }
    `,
    variables: {
      projectId,
      type
    }
  });
  return response.data.project_unit_type.length
    ? response.data.project_unit_type[0]
    : null;
}

async function getByPk(id) {
  const response = await graphqlClient.query({
    query: gql`
      query getUnitTypeByPk($id: uuid!) {
        project_unit_type_by_pk(id: $id) {
          id
          type
          project_unit_type_images {
            id
            image_url
          }
        }
      }
    `,
    variables: {
      id
    }
  });

  return response.data.project_unit_type_by_pk
    ? response.data.project_unit_type_by_pk
    : null;
}

async function add(projectId, type) {
  const res = await graphqlClient.mutate({
    mutation: gql`
      mutation addProjectUnitType($object: project_unit_type_insert_input!) {
        insert_project_unit_type_one(object: $object) {
          id
          img_url
          type
        }
      }
    `,
    variables: {
      object: {
        type: type,
        project_id: projectId,
        img_url: ""
      }
    }
  });

  return res.data.insert_project_unit_type_one;
}

async function list(projectId) {
  const response = await graphqlClient.query({
    query: gql`
    {
      project_unit_type(where:{
        project_id: {
          _eq:"${projectId}"
        }
      }, order_by:{
        updated_at: desc
      }) {
        id
        type
        project_id
        img_url
        project_unit_type_images {
          id
          image_url
        }
      }
    }
    `
  });
  return response.data.project_unit_type;
}

async function updateUnitTypeFields(projectUnitTypeId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updUnitType($fields: project_unit_type_set_input!) {
        update_project_unit_type_by_pk(pk_columns: {id: "${projectUnitTypeId}" },
          _set: $fields) {
          id
          type
          img_url
        }
      }    
    `,
    variables: {
      fields
    }
  });

  return response.data.update_project_unit_type_by_pk;
}

async function addImages(images) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation addPrjUnitTypeImages(
        $images: [project_unit_type_image_insert_input!]!
      ) {
        insert_project_unit_type_image(objects: $images) {
          affected_rows
        }
      }
    `,
    variables: {
      images
    }
  });

  return response.data.insert_project_unit_type_image;
}

async function deleteImageByPk(id) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation delPrjUnitTypeImage($id: uuid!) {
        delete_project_unit_type_image_by_pk(id: $id) {
          id
        }
      }
    `,
    variables: {
      id
    }
  });

  return response.data.delete_project_unit_type_image_by_pk;
}

export default {
  get,
  getByPk,
  add,
  list,
  updateUnitTypeFields,
  addImages,
  deleteImageByPk
};
